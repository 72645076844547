// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'NotoSans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$btn-disabled-opacity: .45;

body.dark-mode{
    --bs-link-color: #90c4ff;
}

body.dark-mode .table{
    --bs-table-hover-bg: rgba(255, 255, 255, 0.075): 
}

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);