// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~scss-common-mixins/index.scss';
//@import '~bootstrap/scss/bootstrap'; //adminlte уже грузит бутстрап
@import 'alte/adminlte.scss';

// Icons
@import "~font-awesome/css/font-awesome.css";

@import "~vue-toastification/dist/index.css";

@import 'fonts.scss';

@import 'home.scss';

@import 'main.scss';

@import 'datepicker.scss';

@import 'slider.scss';

@import 'tiny.scss';