.tox-statusbar__branding,
.tox.tox-tinymce .tox-promotion-link{
    display: none !important;
}

.tox-tinymce {
    border-radius: 4px !important;
}

body.dark-mode .tox-tinymce {
    border-color: var(--bs-gray-600);
    border-width: 1px;
}

body.dark-mode .tox:not(.tox-tinymce-inline) .tox-editor-header {
    background-color: var(--bs-dark);
    border-bottom: 1px solid rgba(255,255,255,.15);
}

.tiny-body-dark,
body.dark-mode .tox .tox-promotion,
body.dark-mode .tox .tox-menubar,
body.dark-mode .tox .tox-toolbar-overlord,
body.dark-mode .tox .tox-tbtn--bespoke,
body.dark-mode .tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child, 
body.dark-mode .tox .tox-toolbar-overlord .tox-toolbar__primary,
body.dark-mode .tox .tox-statusbar {
    background-color: var(--bs-dark);
}

