.home-header {
    .box_up_max {
        height: 80px;
    }

    .box_up_min {
        height: 24px;
    }

    .box {
        position: relative;
        padding: 24px 32px;
        background-color: #d9f7be;
        border-radius: 1rem;

        .title {
            margin-bottom: 16px;
            font-size: 30px;
            font-weight: 400;
        }

        .text {
            width: 60%;
            margin-bottom: 0;
            font-size: 14px;
        }

        .img_max {
            position: absolute;
            width: 234px;
            height: 191px;
            right: 10%;
            bottom: 10%;
        }

        .img_min {
            position: absolute;
            width: 167px;
            height: 95px;
            right: 5%;
            bottom: 5%;
        }
    }
}


.home-login-box{
    border-radius: 1rem;

    .title {
        font-size: 1.5em;
        text-align: center;
    }
}

.home-item-block-list {
    color: #678;
    padding-left: 2rem;
}

.home-item-block {
    margin-bottom: 1em;

    .item-title {
        font-size: 1.5em;
        margin-bottom: 0;
    }
    .item-text {
        font-size: 1em;
    }
    i {
        color: #678;
        display: block;
        height: 100%;
    }
}

.footer {
    color: #678;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    text-align: center;
}
