/** MAIN **/
.float-right{
    float: right;
}

.btn span{
    font-size: var(--bs-btn-font-size);
}

.btn-block{
    display: block !important;
}

.invalid{
    border-color: #dc3545;
}

small.invalid {
    color: red;
}

.d-flex-none{
    flex: none;
}
.fw-normal{
    font-weight: normal !important;
}
/** MAIN **/




/** LINK **/
.nav-link,
.link,
a{
    cursor: pointer;
}

.link{
    color: var(--bs-link-color);
}

.link:hover{
    color: var(--bs-link-color) !important;
    text-decoration: underline;
}

.dark-mode .navbar-nav .nav-link:hover{
    color: var(--bs-white);
}
/** LINK **/




/** TABLE **/
.dark-mode .table-hover > tbody > tr:hover > *{
    color: var(--bs-white);
}

.table-tr-head{
    font-weight: 600;
}

.table-collapse-xxl td,
.table-collapse-xl td {
    padding: 0.5rem 0.5rem !important;
    white-space: normal;
}

.table-collapse-xxl td span{
    font-weight: 700;
}

.dark-mode .table-hover tbody > tr:nth-child(odd) td{
    background-color: var(--bs-border-color-translucent);
}
.table-hover tbody > tr:nth-child(odd) td{
    background-color: var(--bs-gray-100);
}

@media(max-width: 1400px){
    table.table-collapse-xxl, .table-collapse-xxl tr, .table-collapse-xxl th, .table-collapse-xxl tbody {
        width: 100%;
        display: block;
    }
    .table-collapse-xxl td{
        display: block;
        float: left;
    }
}


@media(max-width: 800px){
    table.table-collapse-lg, .table-collapse-lg tr, .table-collapse-lg th, .table-collapse-lg tbody {
        width: 100% !important;
        display: block !important;
    }
    .table-collapse-lg td{
        display: block !important;
        float: left !important;
    }
}

@media(max-width: 1200px){
    table.table-collapse-xl, .table-collapse-xl tr, .table-collapse-xl th, .table-collapse-xl tbody {
        width: 100% !important;
        display: block !important;
    }
    .table-collapse-xl td{
        display: block !important;
        float: left !important;
    }
    table.table-collapse-xl .table-tr-head{
        display: none !important;
    }
}

/** TABLE **/




/** ITEM **/
.num-label {
    position: relative;
    font-weight: 600;
    display: block;
    cursor: auto;
    font-size: 18px;
    text-align: center;
    border: 2px solid var(--bs-gray-500);
    color: var(--bs-gray-500);
    border-radius: 4px;
    line-height: 42px;
}

.num-label>.switcher-container {
    position: absolute;
    left: 10px;
    top: 11px;
}

.num-label.confirmed,
.num-label.auto_confirmed {
    border-color: var(--bs-success);
    color: var(--bs-success);
}

.num-label.waiting {
    border-color: var(--bs-warning);
    color: var(--bs-warning);
}

.num-label.not_confirmed {
    border-color: var(--bs-danger);
    color: var(--bs-danger);
}

@media (min-width: 768px) {
    .item .card-body>div:nth-child(2) {
        border-right: 1px solid var(--bs-gray-500);
        border-left: 1px solid var(--bs-gray-500);
        padding-right: 0 15px;
    }

    .item .card-body>div:nth-child(1) {
        padding-right: 15px;
    }

    .item .card-body>div:nth-child(3) {
        padding-left: 15px;
    }
}

@media (max-width: 768px) {
    .item-actions,
    .item-persons {
        margin-bottom: 1rem;
    }
}

.item-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
}

.item-option>div:last-child {
    text-align: right;
}

.item-actions .btn {
    padding-left: 0px !important;
    padding-right: 0px !important
}
/** ITEM **/

