@import '../../node_modules/@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

body.dark-mode .dp__theme_light {
    --dp-background-color: var(--bs-dark);
    --dp-text-color: var(--bs-white);
    --dp-hover-color: var(--bs-gray-900);
    --dp-hover-text-color: var(--bs-white);
    --dp-hover-icon-color: var(--bs-white);
    --dp-primary-color: var(--bs-gray-700);
    --dp-primary-disabled-color: var(--bs-gray-600);
    --dp-primary-text-color: var(--bs-white);
    --dp-secondary-color: var(--bs-gray-300);
    --dp-border-color: var(--bs-gray-600);
    --dp-menu-border-color: var(--bs-gray-600);
    --dp-border-color-hover: var(--bs-gray-600);
    --dp-disabled-color: var(--bs-gray-600);
    --dp-scroll-bar-background: var(--bs-dark);
    --dp-success-color: var(--bs-white);
    --dp-success-color-disabled: var(--bs-white);
    --dp-icon-color: var(--bs-white);
    --dp-disabled-color-text: var(--bs-gray-600);
    --dp-highlight-color: var(--bs-gray-600);
}

.dp__input_wrap .dp__clear_icon{
    background-color: var(--bs-white);
    right: 1px;
}

body.dark-mode .dp__input_wrap .dp__clear_icon{
    background-color: var(--bs-dark);
}