$fonts: NotoSans;

$weights: (
    Thin: 100,
    ExtraLight: 200,
    Light: 300,
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
    ExtraBold: 800,
    Black: 900
);

@each $font in $fonts {
    @each $name, $weight in $weights {
        @font-face {
            font-family: $font;
            font-style: normal;
            font-weight: $weight;
            src: url(/fonts/#{$font}/#{$font}-#{$name}.ttf) format('ttf');
        }
        @font-face {
            font-family: $font;
            font-style: italic;
            font-weight: $weight;
            src: url(/fonts/#{$font}/#{$font}-#{$name}Italic.ttf) format('ttf');
        }
    }
}