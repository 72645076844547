//
// Misc: Colors
//

// Background colors (theme colors)
@each $name, $color in $theme-colors {
  @include background-variant($name, $color);
}

// Background colors (colors)
@each $name, $color in $colors {
  @include background-variant($name, $color);
}

@media print {
  .table td,
  .table th {
    // Background colors (theme colors)
    @each $name, $color in $theme-colors {
      @include background-variant($name, $color);
    }

    // Background colors (colors)
    @each $name, $color in $colors {
      @include background-variant($name, $color);
    }
  }
}

.bg-gray {
  background-color: $gray-500;
  color: color-contrast($gray-500);
}

.bg-gray-light {
  background-color: lighten($gray-200, 3%);
  color: color-contrast(lighten($gray-200, 3%)) !important;
}

.bg-black {
  background-color: $black;
  color: color-contrast($black) !important;
}

.bg-white {
  background-color: $white;
  color: color-contrast($white) !important;
}

// Gradient Background colors (theme colors)
@each $name, $color in $theme-colors {
  @include background-gradient-variant($name, $color);
}

// Gradient Background colors (colors)
@each $name, $color in $colors {
  @include background-gradient-variant($name, $color);
}

// Backgrund Color Disabled
[class^="bg-"].disabled {
  opacity: .65;
}

// Text muted hover
a.text-muted:hover {
  color: theme-color("primary") !important;
}

// Link Styles
.link-muted {
  color: darken($gray-500, 30%);

  &:hover,
  &:focus {
    color: darken($gray-500, 40%);
  }
}

.link-black {
  color: $gray-600;

  &:hover,
  &:focus {
    color: lighten($gray-500, 20%);
  }
}

// Accent colors (theme colors)
@each $name, $color in $theme-colors {
  @include accent-variant($name, $color);
}

// Accent colors (colors)
@each $name, $color in $colors {
  @include accent-variant($name, $color);
}

// Accent button override fix
[class*="accent-"] {
  @each $name, $color in $theme-colors {
    a.btn-#{$name} {
      color: color-contrast($color);
    }
  }
}

@include dark-mode () {
  .bg-light {
    background-color: lighten($dark, 7.5%) !important;
    color: $white !important;
  }
  .text-black,
  .text-dark,
  .link-black,
  .link-dark {
    color: $gray-400 !important;
  }

  // Background colors (theme colors)
  @each $name, $color in $theme-colors-alt {
    @include background-variant($name, $color);
  }

  // Background colors (colors)
  @each $name, $color in $colors-alt {
    @include background-variant($name, $color);
  }

  // Gradient Background colors (theme colors)
  @each $name, $color in $theme-colors-alt {
    @include background-gradient-variant($name, $color);
  }

  // Gradient Background colors (colors)
  @each $name, $color in $colors-alt {
    @include background-gradient-variant($name, $color);
  }

  // Accent colors (theme colors)
  @each $name, $color in $theme-colors-alt {
    @include accent-variant($name, $color);
  }

  [class*="accent-"] {
    @each $name, $color in $theme-colors-alt {
      a.btn-#{$name} {
        color: color-contrast($color);
      }
    }
  }

  // Accent colors (colors)
  @each $name, $color in $colors-alt {
    @include accent-variant($name, $color);
  }

  .border-dark {
    border-color: lighten($dark, 10%) !important;
  }
}

//
