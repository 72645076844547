$themeColor: var(--bs-primary);

@import '~vue-slider-component/lib/theme/material.scss';

.vue-slider-rail {
    background-color: rgb(0 123 255 / 25%);
}

body.dark-mode .vue-slider-rail {
    background-color: var(--bs-dark);
}

body.dark-mode .vue-slider-dot-tooltip-inner,
body.dark-mode .vue-slider-dot-handle,
body.dark-mode .vue-slider-process{
    background-color: var(--bs-gray-600);
}

